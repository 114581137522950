/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
}

.SRLPanzoomImage {
  width: 40% !important;
}

#SRLLightbox {
  background-color: rgba(111, 105, 105, 0.95);
}

iframe#launcher {
  z-index: 100 !important;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  text-size-adjust: 100%;
  /* background-color: #f8f9fa; Neutral background color */
  /* overflow-x: hidden; */
}

body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

.MuiDialog-root {
  backdrop-filter: blur(1px); /* Apply background blur */
}

.zsiq_floatmain {
  display: none !important;
}

.zsiq_floatmain.visible {
  display: block !important;
  height: 60px !important;
  z-index: 1000 !important;
  margin-right: 15px;
}
@media (min-width: 645px) {
  .zls-sptwndw.zsiq-newtheme {
    z-index: 1000 !important;
  }
}
@media (max-width: 645px) {
  .hide-chatbot .zsiq_floatmain.visible {
    display: none !important;
  }
  div#titlediv {
    display: none;
  }
}
#main-scrollbar .ps__rail-x,
#main-scrollbar .ps__rail-y {
  opacity: 0.6;
}
.hide-promotions .promotion-item {
  display: none;
}

.cnx-main-container {
  border-radius: 10px;
}
